:root {
    --text_default_font_weight: 400;
    --text_medium_font_weight: 500;
    --text_bold_font_weight: 600;

    --text_small_font_size: 12px;
    --text_default_font_size: 13px;
    --text_large_font_size: 14px;
    --subtitle_font_size: 18px;

    --text_small_line_height: 14px;
    --text_default_line_height: 16px;
    --subtitle_line_height: 24px;
}