:root {
  --input_height: 40px;
  --input_text_font_size: 13px;
  --input_text_line_height: 16px;
  --input_text_font_weight: 400;
  --input_border_width: 1px;
  --input_border_radius: 4px;
  --input_outline_weight: 2px;
  --input_disabled_opacity: 50%;
  --input_prefix_margin_left: 12px;
  --input_padding: 4px 12px;

  --input_bg_default: #f4f5f6;
  --input_box_shadow_default: #f4f5f6;
  --input_text_color_default: #363e49;
  --input_placeholder_color_default: #7b899e;
  --input_border_color_default: #f4f5f6;
  --input_outline_color_default: #ffffff00;

  --input_bg_hover: #f4f5f6;
  --input_box_shadow_hover: #f4f5f6;
  --input_text_color_hover: #7#363E49;
  --input_placeholder_color_hover: #7b899e;
  --input_border_color_hover: #616f84;
  --input_outline_color_hover: #ffffff00;

  --input_bg_active: #f4f5f6;
  --input_box_shadow_active: #f4f5f6;
  --input_text_color_active: #363e49;
  --input_placeholder_color_active: #7b899e;
  --input_border_color_active: #1a76ff;
  --input_outline_color_active: #1a76ff;

  --input_bg_disabled: #f4f5f6;
  --input_box_shadow_disabled: #f4f5f6;
  --input_text_color_disabled: #363e49;
  --input_placeholder_color_disabled: #7b899e;
  --input_border_color_disabled: #f4f5f6;
  --input_outline_color_disabled: #ffffff00;

  // TODO: добавить состояния для error
}
