:root {
  --card_radius: 10px;

  --text_default: #26282a;
  --text_error: #cb0935;

  --input_border_default: #cccccc;
  --input_border_hover: #40a9ff;
  --input_border_focused: #0079c8;

  --input_placeholder_default: #b4bac9;

  --input_label_default: #99a0a5;

  --input_label_font_size: 14px;

  --input_radius: 4px;
  --input_font_size: 16px;

  --select_dropdown_font_size: 16px;
}
